import { StatusBar, Style } from '@capacitor/status-bar';
import { deleteItem, getItem } from '../storage/local';
import { RamCheck } from 'mm-capacitor-ram-check';

export default class Spark_Helpers {
    constructor(p) {
        this._ = p;
        this._dblCnt = 0;
    }

    _setStatusBarStyle(style) {
        if (this._.platform() !== 'web') {
            StatusBar.setStyle({ style });

            if (this._.platform() == 'android') {
                //set android top bar to be transparent
                StatusBar.setOverlaysWebView({
                    overlay: true,
                });
            }
        }
    }

    setStatusBarForLightPage() {
        this._setStatusBarStyle(Style.Light);
    }

    setStatusBarForDarkPage() {
        this._setStatusBarStyle(Style.Dark);
    }

    themeColorSplit(color) {
        if (color == '') {
            return;
        }

        let hex = '#374760';
        let rgb;
        if (color.startsWith('#')) {
            hex = color;
        }

        hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (hex) {
            rgb = parseInt(hex[1], 16) + ', ' + parseInt(hex[2], 16) + ', ' + parseInt(hex[3], 16);
        }

        return rgb;
    }

    shakeMe(ref) {
        if (!ref) {
            return;
        }

        ref.classList.add('error-shake');
        setTimeout(() => {
            ref.classList.remove('error-shake');
        }, 820);
    }

    dblClick() {
        this._dblCnt += 1;
        if (this._dblCnt >= 2) {
            return true;
        }
        setTimeout(() => {
            this._dblCnt = 0;
        }, 500);
        return false;
    }

    resizePicture(size, asset, canvas) {
        const image = new Image();
        image.onload = () => {
            const maxsize = size;
            const ratio = Math.min(maxsize / image.width, maxsize / image.height);
            let width = image.width * ratio;
            let height = image.height * ratio;
            if (width < maxsize) {
                height += maxsize - width;
                width = maxsize;
            } else if (height < maxsize) {
                width += maxsize - height;
                height = maxsize;
            }
            let x = 0,
                y = 0;
            if (width > maxsize) {
                x = (maxsize - width) / 2;
            }
            if (height > maxsize) {
                y = (maxsize - height) / 2;
            }
            canvas.getContext('2d').drawImage(image, x, y, width, height);
        };
        image.src = asset;
    }

    calculateExpiryTime(time, timeout) {
        const parsed = /^(?:([0-9]{1,2})d)?(?:([0-9]{1,2})h)?(?:([0-9]{1,2})m)?$/.exec(timeout);

        if (parsed?.length) {
            if (parsed[1]) {
                time.setTime(time.getTime() + parseInt(parsed[1]) * 86400000); //add X days
            }
            if (parsed[2]) {
                time.setTime(time.getTime() + parseInt(parsed[2]) * 3600000); //add X hours
            }
            if (parsed[3]) {
                time.setTime(time.getTime() + parseInt(parsed[3]) * 60000); //add X minutes
            }
        }

        return time;
    }

    isMobile() {
        const uaString = navigator.userAgent;
        return uaString.match(/iPad/i) || uaString.match(/iPhone/i);
    }

    isIPhone() {
        const uaString = navigator.userAgent;
        return uaString.match(/iPhone/i);
    }

    getBrowserName() {
        let sBrowser = '';
        const uaString = navigator.userAgent;

        if (uaString.indexOf('Firefox') > -1) {
            sBrowser = 'firefox';
        } else if (uaString.indexOf('SamsungBrowser') > -1) {
            sBrowser = 'samsung';
        } else if (uaString.indexOf('Opera') > -1 || uaString.indexOf('OPR') > -1) {
            sBrowser = 'opera';
        } else if (uaString.indexOf('Trident') > -1) {
            sBrowser = 'internet explorer';
        } else if (uaString.indexOf('Edge') > -1) {
            sBrowser = 'edge (legacy)';
        } else if (uaString.indexOf('Edg') > -1) {
            sBrowser = 'edge (chromium)';
        } else if (uaString.indexOf('Chrome') > -1) {
            sBrowser = 'chrome';
        } else if (uaString.indexOf('Safari') > -1) {
            sBrowser = 'safari';
        } else {
            sBrowser = 'unknown';
        }

        return sBrowser;
    }

    filterCollaterals(list, additionalFileRegex) {
        const validFiles = /(.mp4|.png|.jpg|.jpeg)$/;

        return list
            .filter((c) => {
                return (
                    c.File.startsWith('collaterals/') &&
                    !c.File.endsWith('kiosk.png') &&
                    !c.File.endsWith('contact.png') &&
                    !c.File.endsWith('splash.png') &&
                    (validFiles.test(c.File) || additionalFileRegex?.test(c.File))
                );
            })
            .sort((a, b) => {
                if (a.File.endsWith('.url')) {
                    return 1;
                }
                if (b.File.endsWith('.url')) {
                    return -1;
                }

                return a.File.localeCompare(b.File);
            });
    }

    setTextStyle(node, styles) {
        if (!node) {
            return;
        }

        if (styles.fontsize) {
            node.style.fontSize = `${styles.fontsize}px`;
        }

        if (styles.letterspacing) {
            node.style.letterSpacing = `${styles.letterspacing}em`;
        }

        if (styles.accent) {
            node.classList.add('accentRGB');
        }

        switch (styles.style) {
            case undefined:
            case 'plain':
                break;
            case 'italic':
                node.style.fontStyle = 'italic';
                break;
            case 'bold':
                node.style.fontWeight = 'bold';
                break;
            case 'underline':
                node.style.textDecoration = 'underline';
                break;
        }
    }

    replaceText(text, map = {}) {
        const regMatch = /{{(.+?)}}/g;
        let match,
            matches = [],
            c = 0;
        while ((match = regMatch.exec(text)) != null && c++ < 1000) {
            if (match.length > 1 && matches.indexOf(match[1]) == -1) {
                matches.push(match[1]);
            }
        }

        if (!matches.length) {
            return text;
        }

        let skipped = 0;
        for (let i = 0; i < matches.length; i++) {
            if (map[matches[i]] === undefined) {
                map[matches[i]] = '';
                skipped++;
            }

            text = text.replace(new RegExp(`{{${matches[i]}}}`, 'g'), map[matches[i]] || '');
        }

        if (skipped >= matches.length) {
            text = '';
        }

        return text;
    }

    detectFlexGapSupport() {
        const testContainer = document.createElement('div');
        testContainer.style.display = 'flex';
        testContainer.style.flexDirection = 'column';
        testContainer.style.rowGap = '1px';
        testContainer.style.opacity = 0;

        testContainer.appendChild(document.createElement('div'));
        testContainer.appendChild(document.createElement('div'));

        document.body.appendChild(testContainer);

        const isSupported = testContainer.scrollHeight === 1;

        testContainer.parentNode.removeChild(testContainer);

        return isSupported;
    }

    formatText(text) {
        const lines = text.split('\n');
        let formatted = [];
        let inOrderList = false;
        let noOrderList = false;
        const biRegex = /(\*\*\*(.+?)\*\*\*)/;
        const bRegex = /(\*\*(.+?)\*\*)/;
        const iRegex = /(\*(.+?)\*)/;

        lines.forEach((l) => {
            const len = formatted.length;

            let hasBiRegex = null;
            do {
                hasBiRegex = biRegex.exec(l);
                if (hasBiRegex && hasBiRegex.length == 3) {
                    l = l.replace(hasBiRegex[1], '<b><em>' + hasBiRegex[2] + '</em></b>');
                }
            } while (hasBiRegex != null);

            let hasBRegex = null;
            do {
                hasBRegex = bRegex.exec(l);
                if (hasBRegex && hasBRegex.length == 3) {
                    l = l.replace(hasBRegex[1], '<b>' + hasBRegex[2] + '</b>');
                }
            } while (hasBRegex != null);

            let hasIRegex = null;
            do {
                hasIRegex = iRegex.exec(l);
                if (hasIRegex && hasIRegex.length == 3) {
                    l = l.replace(hasIRegex[1], '<em>' + hasIRegex[2] + '</em>');
                }
            } while (hasIRegex != null);

            l = l.replace(/__/g, '<br>');

            if (l.startsWith('###### ')) {
                formatted.push('<h6>' + l.replace('###### ', '') + '</h6>');
            } else if (l.startsWith('##### ')) {
                formatted.push('<h5>' + l.replace('##### ', '') + '</h5>');
            } else if (l.startsWith('#### ')) {
                formatted.push('<h4>' + l.replace('#### ', '') + '</h4>');
            } else if (l.startsWith('### ')) {
                formatted.push('<h3>' + l.replace('### ', '') + '</h3>');
            } else if (l.startsWith('## ')) {
                formatted.push('<h2>' + l.replace('## ', '') + '</h2>');
            } else if (l.startsWith('# ')) {
                formatted.push('<h1>' + l.replace('# ', '') + '</h1>');
            }

            if (/^[0-9]+\. /.test(l)) {
                if (!inOrderList) {
                    inOrderList = true;
                    formatted.push('<ol>');
                }
                formatted.push('<li>' + l.replace(/^[0-9]+\. /, '') + '</li>');
            } else if (inOrderList) {
                inOrderList = false;
                formatted.push('</ol>');
            }

            if (/^- /.test(l)) {
                if (!noOrderList) {
                    noOrderList = true;
                    formatted.push('<ul>');
                }
                formatted.push('<li>' + l.replace(/^- /, '') + '</li>');
            } else if (noOrderList) {
                noOrderList = false;
                formatted.push('</ul>');
            }

            if (l == '') {
                formatted.push('<br>');
            }

            if (len == formatted.length) {
                formatted.push('<p>' + l + '</p>');
            }
        });

        if (inOrderList) {
            formatted.push('</ol>');
        }
        if (noOrderList) {
            formatted.push('</ul>');
        }

        return formatted.join('');
    }

    buttonLoading(e) {
        const s = e.querySelector('.status');
        if (s) {
            s.innerText = '';
        }
        e.setAttribute('loading', '');
        ['button-valid', 'button-invalid', 'button-invalid-try-again'].forEach((c) => {
            e.removeAttribute(c);
        });
    }
    buttonStopLoading(e) {
        ['loading', 'button-valid', 'button-invalid', 'button-invalid-try-again'].forEach((c) => {
            e.removeAttribute(c);
        });
    }
    buttonSuccess(e) {
        const b = e.querySelector('button');
        b.classList.remove('accentRGB', 'red');
        b.classList.add('green');

        const text = b.querySelector('.text');
        if (text.getAttribute('data-valid-text')) {
            text.setAttribute('data-original-text', text.innerText);
            text.innerText = text.getAttribute('data-valid-text');
        }

        e.setAttribute('button-valid', '');
        ['loading', 'button-invalid', 'button-invalid-try-again'].forEach((c) => {
            e.removeAttribute(c);
        });
    }
    buttonActionCompleted(e) {
        const b = e.querySelector('button');
        b.classList.remove('accentRGB', 'red');
        b.classList.add('blue');

        const text = b.querySelector('.text');
        if (text.getAttribute('action-completed')) {
            text.setAttribute('data-original-text', text.innerText);
            text.innerText = text.getAttribute('action-completed');
        }

        e.setAttribute('button-valid', '');
        ['loading', 'button-invalid', 'button-invalid-try-again'].forEach((c) => {
            e.removeAttribute(c);
        });
    }
    buttonFailure(e) {
        const b = e.querySelector('button');
        b.classList.remove('accentRGB', 'green');
        b.classList.add('red');

        const text = b.querySelector('.text');
        if (text.getAttribute('data-invalid-text')) {
            text.setAttribute('data-original-text', text.innerText);
            text.innerText = text.getAttribute('data-invalid-text');
        }

        e.setAttribute('button-invalid', '');
        ['loading', 'button-valid', 'button-invalid-try-again'].forEach((c) => {
            e.removeAttribute(c);
        });
    }
    buttonReset(e) {
        if (!e) {
            return;
        }
        const b = e.querySelector('button');
        if (!b) {
            return;
        }
        b.classList.remove('red', 'green');
        b.classList.add('accentRGB');

        const text = b.querySelector('.text');
        if (text.getAttribute('data-original-text')) {
            text.innerText = text.getAttribute('data-original-text');
            text.removeAttribute('data-original-text');
        }

        e.setAttribute('button-invalid-try-again', '');
        ['loading', 'button-valid', 'button-invalid'].forEach((c) => {
            e.removeAttribute(c);
        });
    }

    getSubmissionStartDate(description) {
        let now = new Date();
        now.setMilliseconds(0);
        now.setSeconds(0);
        now.setMinutes(0);
        switch (description) {
            case 'today':
                now.setHours(0);
                break;
            case 'week': //https://stackoverflow.com/a/4156516
                now.setDate(now.getDate() - now.getDay()); //set to sunday
                now.setHours(0);
                break;
            case 'month':
                now.setDate(1);
                now.setHours(0);
                break;
            case 'lastmonth':
                now.setMonth(now.getMonth() - 1);
                now.setDate(1);
                now.setHours(0);
                break;
            case 'thishour':
                break;
            case 'twohours':
                now.setHours(now.getHours() - 2);
                break;
            case 'sixhours':
                now.setHours(now.getHours() - 6);
                break;
        }

        return now;
    }

    getSubmissionEndDate(description) {
        let now = new Date();
        now.setMilliseconds(0);
        now.setSeconds(0);
        now.setMinutes(0);
        switch (description) {
            case 'today':
            case 'week': //https://stackoverflow.com/a/4156516
            case 'month':
                break;
            case 'lastmonth':
                now.setDate(0);
                now.setHours(23);
                now.setMinutes(59);
                now.setSeconds(59);
                now.setMilliseconds(999);
                break;
            case 'thishour':
            case 'twohours':
            case 'sixhours':
                break;
        }

        return now;
    }

    getDuration(ms) {
        const hours = Math.floor(ms / 3600000);
        if (hours >= 1) {
            ms -= hours * 3600000;
        }

        const minutes = Math.floor(ms / 60000);
        if (minutes >= 1) {
            ms -= minutes * 60000;
        }

        const seconds = Math.floor(ms / 1000);

        return {
            hours,
            minutes,
            seconds,
        };
    }

    formatClock(seconds, settings) {
        let hours;
        if (settings?.includeHours) {
            hours = Math.floor(seconds / 3600);
            seconds -= hours * 3600;
        }

        const minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        const format = [('0' + minutes.toString()).slice(-2)];
        if (settings.includeHours) {
            format.unshift(('0' + hours.toString()).slice(-2));
        }
        if (settings.includeSeconds) {
            format.push(('0' + seconds.toString()).slice(-2));
        }

        return format.join(settings?.join ?? ' : ');
    }

    formHasRecording(form) {
        function elementsIsRecording(list) {
            for (let j = 0; j < list.length; j++) {
                if (list[j].elements) {
                    const recording = elementsIsRecording(list[j].elements);
                    if (recording) {
                        return recording;
                    }
                } else if (list[j].type == 'voicerecording' && !list[j].skip_consent) {
                    return list[j];
                }
            }

            return false;
        }

        for (let j = 0; j < form?.sections?.length; j++) {
            const recording = elementsIsRecording(form.sections[j].elements);
            if (recording) {
                return recording;
            }
        }

        return false;
    }

    localizeOrCustomString($t, str, def) {
        if (!str) {
            return $t(def);
        }

        if (str.startsWith('$')) {
            return $t(str.substring(1));
        }
        return str;
    }

    async delay(t, v) {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t);
        });
    }

    async waitUntil(max, v) {
        const startTime = Date.now();
        return new Promise(function (resolve) {
            const x = setInterval(() => {
                if (v) {
                    clearInterval(x);
                    resolve(v);
                } else if (Date.now() - startTime > max) {
                    clearInterval(x);
                    resolve();
                }
            }, 100);
        });
    }

    async removeCachedSubmission() {
        await deleteItem('cachedsub');
    }

    async getCachedSubmission(campaignid) {
        let cachedsub = await getItem('cachedsub');
        if (!cachedsub) {
            return;
        }

        try {
            cachedsub = JSON.parse(cachedsub);
        } catch (e) {
            console.log('Could not parse cached submission');
            await this.removeCachedSubmission();
            return;
        }

        if (campaignid && campaignid != cachedsub?.meta?.campaignid) {
            console.log('We do not have this campaign assigned');
            await this.removeCachedSubmission();
            return;
        }

        const queued = await this._.Queue.getStoredSubmissions();
        if (queued && queued.files?.length) {
            const matches = queued.files.filter((c) => {
                const ids = c.replace('.ezip', '').split('_');
                const subid = ids[1];

                return cachedsub?.meta?.id == subid;
            });

            if (matches.length) {
                console.log('This submission was already submitted');
                await this.removeCachedSubmission();
                return;
            }
        }

        const result = await this._.API.findID(cachedsub?.meta?.id);
        if (result?.status != 404) {
            await this.removeCachedSubmission();
            return;
        }

        if (Date.now() - cachedsub?.actions?.lastAction >= 900000) {
            //15m
            console.log('The submission is too old');
            await this.removeCachedSubmission();
            return;
        }

        return cachedsub;
    }

    async getRAMInfo() {
        return await RamCheck.getRAMInfo();
    }

    async getDeviceInfo() {
        return await RamCheck.getDeviceInfo();
    }

    generateUserAgent() {
        let base = navigator.userAgent;
        const opts = this._.store.getters['config/deviceInfo'];
        if (this._.platform() == 'ios') {
            base = base.replace(/Mobile\/.+?\b/, ''); //remove "mobile" to stop certain sites treating as mobile
            base += ` Version/${opts.browser_version} Safari/`; //and add missing version info...
        } else if (this._.platform() == 'android') {
            base = base.replace(/Android .+\b/, ''); //remove android to stop certain sites treating as mobile
        }
        return base;
    }

    titleCase(str) {
        let a = str.replace(/\b\w+/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
        a = a.replace(/['’”‘“][A-Z]{1}\b/g, (txt) => {
            return txt.toLowercase();
        });
        a = a.replace(
            /[àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ](.)/g,
            (aa, b) => {
                return aa.charAt(0) + b.toLowerCase();
            }
        );

        return a;
    }
}
